$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

$calendar-border: #DDD !default;
$cell-border: #DDD !default;

$border-color: #CCC !default;

$segment-width: percentage(1 / 7) !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, .50) !default;
$date-selection-bg-color: rgba(0, 0, 0, .10) !default;


$event-bg: #6D5CAE !default;
$event-border: darken(#6D5CAE, 10%) !default;
$event-outline: #3b99fc !default;
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #eaf6ff !default;

@import 'react-big-calendar/lib/sass/styles';

.rbc-toolbar {
    background-color: #F5F5F5;
    margin-bottom: 0;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        .rbc-btn-group {
            margin-bottom: 20px;
        }
        .rbc-toolbar-label {
            order: 1;
        }
    }
}

.business-selector {
    position: absolute;
    right: 230px;
    top: 75px;
    z-index: 10;

    @media screen and (max-width: 500px) {
        position: static;
        padding-bottom: 10px;
    }

    &.brandAmbassador {
        display: none;
    }

    &.eventCreator {
        display: none;
    }
}