@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:500,700&display=swap');
html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"segoe ui",roboto,oxygen,ubuntu,cantarell,"fira sans","droid sans","helvetica neue",sans-serif;
    background: rgb(245, 245, 245);
    font-size: 13px;
    color: rgb(98, 98, 98);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    color: rgb(44, 44, 44);
}

@keyframes checkbox {
  0% {background-position: 0;}
  100% {background-position: -144px;}
}